<template>
    <div class="target-lens-table-container">
        <InventoryLookup
            :side="side"
            :showModal.sync="showInventoryModal"
            :preOpDataId="preOpDataId"
            :lengthOptions="lengthOptions"
            :selectedLengthOption="selectedLengthOption"
            :lensInformation="lensInformation"
            :targetLensDescription="targetLensDescription"
            :allLensInfo="allLensInfo"
            @on-locked="onIventoryLookupLocked"
            @on-selected-length-change="save"
        />
        <b-card
            footer-bg-variant="white"
            :header-class="[
                'target-lens-header text-center text-md-left d-flex flex-column flex-md-row justify-content-between',
                {locked: isLocked},
            ]"
            :no-body="true"
        >
            <div v-if="mixedAstigmatismWarning" class="overlay">
                <b-card footer-class="text-center" class="msg-card">
                    <div v-html="t('preop_MixedAstigmatism')"></div>

                    <template #footer>
                        <b-button @click="backOrCancel(true)" class="mr-2">{{
                            t('cancel')
                        }}</b-button>
                        <b-button @click="mixedAstigmatismWarning = false" variant="primary">{{
                            t('continue')
                        }}</b-button>
                    </template>
                </b-card>
            </div>
            <div v-else class="overlay" v-show="!!targetStateMsg">
                <b-card footer-class="text-center" class="msg-card">
                    <div v-html="t(targetStateMsg)"></div>

                    <template #footer>
                        <b-button @click="targetStateMsg = null">{{ t('close') }}</b-button>
                    </template>
                </b-card>
            </div>
            <template #header>
                <div class="d-md-inline">
                    <span
                        class="d-block d-md-inline text-big heavy text-white pl-0 pt-1 mt-2 side-text"
                        >{{ side }}</span
                    >
                    <b-img
                        :src="icon"
                        class="d-block d-md-inline mx-md-2 mb-md-4 m-auto py-3 py-md-0"
                    ></b-img>
                    <span
                        class="d-block d-md-inline text-title heavy text-white position-relative"
                        style="bottom: 10px"
                        >{{ t(isLocked ? 'preop_CalculationLocked' : 'preop_CalculationComplete') }}
                    </span>
                    <span
                        class="d-block d-md-inline body-text text-white position-relative"
                        style="bottom: 10px"
                    >
                        <span class="d-inline-block ml-2">
                            {{ t(isLocked ? 'preop_SelectALensLocked' : 'preop_SelectALens') }}
                        </span>
                    </span>
                </div>
                <div
                    class="d-block d-md-inline body-text text-white mx-3 position-relative align-self-center text-right"
                >
                    <span class="d-md-block">{{ calculationDate | date }}</span>
                    <span class="d-md-block">{{ t('preopreport_CalculatedWith') }}</span>
                    <span class="d-md-block">{{ apiInformation.version }}</span>
                </div>
            </template>

            <div id="table-container">
                <b-table
                    selectable
                    responsive
                    ref="targetLensSelectionTable"
                    select-mode="single"
                    :class="['target-lens-selection-table mt-0', {locked: isLocked}]"
                    :id="tableId"
                    :sticky-header="true"
                    :fields="fields"
                    :items="getListLensData"
                    :tbody-tr-attr="rowClass"
                    :no-select-on-click="!canEdit || isLocked"
                    @row-selected="onRowSelected"
                >
                    <template #cell(expAxis)="data">
                        <span>
                            {{ padNumber(data.value) }}
                        </span>
                    </template>

                    <template #cell(sphere)="data">
                        <span>
                            {{ formatLensNum(data.value, 2, decimalSeparator) }}
                        </span>
                    </template>

                    <template #cell(cylinder)="data">
                        <span>
                            {{ formatLensNum(data.value, cylinderDecimals, decimalSeparator, 1) }}
                        </span>
                    </template>

                    <template #cell(expSphere)="data">
                        <span>
                            {{ formatLensNum(data.value, 2, decimalSeparator) }}
                        </span>
                    </template>

                    <template #cell(expCylinder)="data">
                        <span>
                            {{ formatLensNum(data.value, 2, decimalSeparator) }}
                        </span>
                    </template>

                    <template #cell(expSeq)="data">
                        <span>
                            {{ formatLensNum(data.value, 2, decimalSeparator) }}
                        </span>
                    </template>
                </b-table>
            </div>

            <div class="d-flex flex-wrap align-items-center justify-content-between px-4 pt-1 pb-2">
                <div class="d-flex align-items-center">
                    <template v-if="isOUSZone">
                        <b-form-checkbox
                            v-model="lengthSelectionEnabled"
                            :disabled="!canEdit || !lengthOptions.length || isLocked"
                            switch
                            size="lg"
                            class="different-length-checkbox mr-2"
                        >
                            {{ t('preop_DiffLengthSel') }}</b-form-checkbox
                        >
                        <b-form-select
                            :options="lengthOptions"
                            v-model="selectedLengthOption"
                            class="text-body light w-auto"
                            :disabled="!canEdit || !lengthSelectionEnabled || isLocked"
                        >
                        </b-form-select>
                    </template>
                    <template v-else>
                        <span class="text-caption text-muted mr-2 fz-1rem">
                            {{ t('preop_DiffLengthSug') }}
                        </span>
                        <div class="text-caption heavy font-weight-bolder text-muted mr-2 fz-1rem">
                            {{ suggestedLengthOptionText }}
                        </div>
                    </template>
                </div>
                <div
                    class="d-flex align-items-center"
                    :class="{invisible: !lensInformation.calculationType.includes(lensModelToric)}"
                >
                    <span
                        class="text-caption heavy text-primary mr-2 fz-1rem"
                        v-show="isPowerChanged"
                    >
                        {{ t('preop_UserSelected') }}
                    </span>
                    <span class="text-caption text-muted mr-2 fz-1rem">
                        {{ t('preop_CylinderPower') }}
                    </span>
                    <b-form-select
                        class="text-body light w-auto cylinder-power-select"
                        :disabled="!canEdit || isLocked"
                        :options="powerOptions"
                        v-model="calculatorResults.cylinder"
                        @change="cylinderPowerChanged"
                    >
                    </b-form-select>
                </div>
            </div>
            <div class="px-4 py-1">
                <b-form-group
                    :label="t('preop_TargetLens')"
                    class="mb-0"
                    label-for="description"
                    label-cols="12"
                    label-cols-sm="2"
                >
                    <b-form-input
                        id="description"
                        class="text-body light text-black pl-2"
                        :disabled="isLocked"
                        :readonly="targetLensDescription.length == 0"
                        :plaintext="targetLensDescription.length > 0 && !isLocked"
                        :placeholder="targetLensDescription"
                        :class="{'target-lens-selection-field': targetLensDescription.length > 0}"
                    ></b-form-input>
                </b-form-group>
            </div>
            <template #footer>
                <div class="d-flex justify-content-between">
                    <div class="d-flex">
                        <b-button v-if="isLocked" @click="save()">
                            {{ t('preop_ViewInventory') }}</b-button
                        >
                    </div>
                    <div class="d-flex">
                        <span v-if="isLocked" class="text-title heavy align-self-center mr-3">
                            <b-img src="@/assets/padlock.svg" height="25" width="25"></b-img>
                            {{ t('preop_CalculationLocked') }}</span
                        >
                        <template v-if="!isLocked">
                            <b-button class="mr-1" @click="backOrCancel()">
                                {{ t('cancel') }}</b-button
                            >
                            <b-button
                                v-if="hasOrderingPermission"
                                :disabled="saveButtonDisabled"
                                @click="save({}, canLookupInventory)"
                                variant="primary"
                            >
                                {{ t(canLookupInventory ? 'invLookupReport_Title' : 'save') }}
                            </b-button>
                        </template>
                    </div>
                </div>
            </template>
        </b-card>
    </div>
</template>
<script>
import {mapGetters, mapMutations} from 'vuex';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {ZoneCodes} from '@/constants/zone';
import {LensModelCodes, Status} from '@/store/modules/preopdata';
import {
    formatLensNum,
    padNumber,
    lensDescriptionObjToString,
    formatNumber,
} from '@/utilities/formatters';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {checkPermissions} from '@/utilities/permissions';
import InventoryLookup from '@/components/InventoryLookup.vue';
import ODIcon from '@/assets/preop-data/white-od-eyes.svg';
import OSIcon from '@/assets/preop-data/white-os-eyes.svg';
import isNil from 'lodash/isNil';

const DISABLED_TEXT_COLOR = '#C2C9D1';
const OFFSET_ROW_TO_BE_IN_MIDDLE = 2;
const TARGET_STATE_MESSAGES_KEY = {
    1: 'preop_NoLensToFullyCorrect',
    2: 'preop_NoLensAvailable',
    3: 'preop_NoLensMfg',
    4: 'preop_NoLensMfgICL',
};

export default {
    components: {
        InventoryLookup,
    },
    props: {
        side: {
            type: String,
            default: null,
        },
        preOpDataId: {
            type: Number,
            default: null,
        },
    },

    data() {
        return {
            ODIcon,
            OSIcon,
            fields: [
                {key: 'sphere', label: this.t('preop_SelSphere')},
                {key: 'cylinder', label: this.t('preop_SelCylinder')},
                {key: 'expSphere', label: this.t('preop_ExpSphere')},
                {key: 'expCylinder', label: this.t('preop_ExpCylinder')},
                {key: 'expAxis', label: this.t('preop_ExpAxis')},
                {key: 'expSeq', label: this.t('preop_ExpSEQ')},
            ],
            selectedRow: {},
            targetStateMsg: null,
            mixedAstigmatismWarning: false,
            showInventoryModal: false,
            lengthSelectionEnabled: false,
            selectedLengthOption: null,
            lensModelToric: LensModelCodes.TORIC,
            cylinderDecimals: 2, //the number of decimal places to show the Sel Cylinder to
            noEmm: false,
        };
    },

    methods: {
        formatLensNum,
        padNumber,
        lensDescriptionObjToString,
        ...mapMutations({
            setPreOpDataStatus: 'preopdata/setPreOpDataStatus',
        }),
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        rowClass(item) {
            if (this.isNotAvailable(item)) return {style: item?.rowStyle};
        },

        isNotAvailable(item) {
            return (
                isEmpty(item) ||
                item.isLensActive === false ||
                item.rowTextColor === DISABLED_TEXT_COLOR
            );
        },

        onRowSelected(item) {
            this.selectedRow = item[0];
        },
        clearSelection() {
            this.selectedRow = {};
            this.calculatorResults.selectedLensIndex = null;
            this.$refs.targetLensSelectionTable.clearSelected();
        },
        async selectRow(index) {
            await this.$refs.targetLensSelectionTable?.selectRow(index);
        },
        selectRecLength(value) {
            // Sets the dropdown value
            this.selectedLengthOption = this.lengthOptions.find(
                (option) => option.value === value
            )?.value;
        },
        focusTableSelection() {
            const table = document.getElementById(this.tableId);
            if (table && table.tBodies[0]) {
                let index =
                    this.lensInformation?.targetIndex < OFFSET_ROW_TO_BE_IN_MIDDLE
                        ? this.lensInformation?.targetIndex
                        : this.lensInformation?.targetIndex - OFFSET_ROW_TO_BE_IN_MIDDLE;

                // If calculatorResults have a selectedIndex previously saved we want to focus this row in the middle of the table
                if (this.calculatorResults.selectedLensIndex) {
                    index =
                        this.calculatorResults.selectedLensIndex < OFFSET_ROW_TO_BE_IN_MIDDLE
                            ? this.calculatorResults.selectedLensIndex
                            : this.calculatorResults.selectedLensIndex - OFFSET_ROW_TO_BE_IN_MIDDLE;
                }

                if (this.hasBlankRows) {
                    index += OFFSET_ROW_TO_BE_IN_MIDDLE;
                }

                // Scroll the table to the indexed row
                // NB: Using `scrollIntoView` here would scroll the whole window, which we don't want.
                const row = table.tBodies[0].rows[index];
                if (row) {
                    let element = table.tBodies[0];
                    const SM_DIMENSION = 576;
                    if (window.innerWidth < SM_DIMENSION) {
                        // At < sm breakpoint, we need to scroll the table's parent element instead
                        element = table.parentElement;
                    }

                    let offsetTop = 0;
                    for (let i = 0; i < index; i++) {
                        let r = table.tBodies[0].rows[i];
                        offsetTop += r.getBoundingClientRect().height;
                    }
                    element.scroll({
                        // Always round up, and add 1 to scroll past the border
                        top: Math.ceil(offsetTop + 1),
                        left: 0,
                    });
                }
            }
        },
        //called when the user selected cylinder power changes
        async cylinderPowerChanged() {
            //do nothing if not any kind of Toric
            if (!this.lensInformation.calculationType.includes(LensModelCodes.TORIC)) return;

            this.clearSelection();
            //get the new allLensInfo, and get the row for the selected power
            //find the entry that has the newly selected power option
            let lensInfo = this.allLensInfo.find(
                (v) => v.cylinder == this.calculatorResults.cylinder
            );
            //lensInfo is only present on new versions of the Calculator API
            if (lensInfo) {
                //want to update the target lens table with this info
                lensInfo.sphere.forEach((sphere, index) => {
                    //find the row in the targetLensData matching this sphere value
                    let row = this.targetLensData.find((v) => v.sphere == sphere);
                    if (row) {
                        row.cylinder = lensInfo.cylinderVals[index];
                        row.expSphere = lensInfo.expSphere[index];
                        row.expCylinder = lensInfo.expCylinder[index];
                        //axis needs to be formatted correctly, remove decimals
                        row.expAxis = parseInt(lensInfo.expAxis[index]);
                        row.expSeq = lensInfo.expSeq[index];
                    }
                });
                // update the table state based on the extra values in the
                // allLensInfo for this cylinder power
                this.noEmm = false;
                if (!isNil(lensInfo.recIndex)) {
                    this.lensInformation.targetIndex = lensInfo.recIndex;
                    this.lensInformation.targetState = lensInfo.noEmm ? 2 : 0;
                    this.noEmm = lensInfo.noEmm;
                } else {
                    this.lensInformation.targetState = 1;
                    this.lensInformation.targetIndex = this.targetLensData.length; //scroll the blank rows into place
                }
            }
            await this.calculatorResultsChange(!this.isOUSZone);
        },
        backOrCancel(shouldTriggerSave) {
            if (this.isLocked) {
                // back
                this.$router.push({
                    name: 'PatientDetails',
                    params: {patientId: this.$route.params.patientId},
                });
            } else {
                // cancel
                this.$emit('cancel', shouldTriggerSave);
            }
        },
        async save(inventoryLookupChangeRequest = {}, showInventoryModal = true) {
            //update the selectedRow to include the selectedLength
            //Initially selectedLength is not part of the selectedRow model, we need to add reactivity
            this.$set(this.selectedRow, 'selectedLength', this.selectedLengthOption);

            //Update the selectedLensIndex with the current selected row
            //Initially selectedLensIndex is not part of the calculatorResults model, we need to add reactivity
            this.$set(this.calculatorResults, 'selectedLensIndex', this.selectedRowIndex);

            try {
                const {success, error} = showInventoryModal
                    ? await this.blockingRequest('preopdata/fetchAvailableLenses', {
                          side: this.side,
                          preOpDataId: this.preOpDataId,
                          payload: {
                              ...pick(inventoryLookupChangeRequest, [
                                  'pendingAddToCart',
                                  'pendingReserve',
                              ]),
                              selectedLens: {
                                  ...this.selectedRow,
                                  selectedLength: get(
                                      inventoryLookupChangeRequest,
                                      'selectedLength',
                                      this.selectedLengthOption
                                  ),
                              },
                          },
                      })
                    : await this.blockingRequest(
                          'preopdata/saveCalculationResultsWithSelectedLens',
                          {
                              side: this.side,
                              preOpDataId: this.preOpDataId,
                              selectedLens: this.selectedRow,
                              calculatorResults: this.calculatorResults,
                          }
                      );
                if (success) {
                    const {success, error} = await this.blockingRequest(
                        'patient/fetchPrimaryLens',
                        {patientId: this.$route.params.patientId, eye: this.side}
                    );
                    if (inventoryLookupChangeRequest.callback) {
                        inventoryLookupChangeRequest.callback();
                    }
                    if (success) {
                        this.showInventoryModal = showInventoryModal;
                    } else {
                        throw error;
                    }
                } else {
                    throw error;
                }
            } catch (error) {
                this.$bvToast.toast(
                    error?.errors?.join(' ') || this.t('preop_SomethingWentWrong'),
                    {
                        title: error?.errorCode || this.t('warning'),
                        variant: 'warning',
                        solid: true,
                    }
                );
            }
        },
        async calculatorResultsChange(shouldChangeLengthSelection = true) {
            // If calculatorResults have selectedLensIndex we want this to be selected by default

            // if we have a TORIC EDOF, use 2 decimals for the Sel Cyl column
            //based on STAAR feedback, we're always going to show two decimals for cylinder now
            //this.cylinderDecimals = this.lensInformation.calculationType == TORIC_EDOF ? 2 : 1;

            if (this.calculatorResults.selectedLensIndex) {
                await this.selectRow(this.calculatorResults.selectedLensIndex);
            }

            // For OUS Length Selection should not reset when cylinder power change
            if (shouldChangeLengthSelection) {
                // Length selection is enabled when selected row have value and it is different from default
                this.lengthSelectionEnabled =
                    this.selectedRow.selectedLength &&
                    this.selectedRow.selectedLength !== this.suggestedLengthOption.value;

                // Sets the dropdown value for the selected or suggested length
                this.selectRecLength(
                    this.selectedRow.selectedLength || this.suggestedLengthOption.value
                );
            }

            this.$nextTick(this.focusTableSelection);

            // Triggering alerts if neccesary
            if ([Status.CALCULATED, Status.TARGET_LENS_PICKED].includes(this.dataSet.status)) {
                this.mixedAstigmatismWarning = !!this.calculatorResults.result.mixedAstig; // US calculator can thrown mixedAstig check
                this.targetStateMsg = TARGET_STATE_MESSAGES_KEY[this.lensInformation.targetState];
            }
        },
        onIventoryLookupLocked() {
            this.setPreOpDataStatus({status: Status.LOCKED, sides: [this.side]});
            this.calculatorResults.selectedLensIndex = this.selectedRowIndex;
            /* We need to use the nextTick function because when the locked variable gets its value to true and
            the component is alive the table deselects  the selection made via click by the user (due to :no-select-on-click="isLocked")
            and we need to select it programmatically and make sure that the focus of the table is well positioned */
            this.$nextTick(this.calculatorResultsChange);
        },
    },

    computed: {
        ...mapGetters('preopdata', ['preopdata']),
        ...mapGetters('zone', ['currentZone']),
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),

        canEdit() {
            return this.checkPermissions({
                [PERMISSIONS.PATIENT_CALCULATIONS]: PERMISSIONS_VALUES.READ_WRITE,
            });
        },

        hasOrderingPermission() {
            return this.checkPermissions({
                [PERMISSIONS.ORDERING]: [
                    PERMISSIONS_VALUES.READ_ONLY,
                    PERMISSIONS_VALUES.READ_WRITE,
                ],
            });
        },
        hasBlankRows() {
            return (
                (!!this.lensInformation.targetState && !this.noEmm) || !this.targetLensData.length
            );
        },
        decimalSeparator() {
            const {decimalSeparator} = this.currentUser;
            return decimalSeparator;
        },

        dataSet() {
            return get(this.preopdata, `preOpData[${this.side}]`, {});
        },

        calculatorResults() {
            return get(this.dataSet, `calculatorResults`, {});
        },

        calculationDate() {
            return get(this.calculatorResults, `calculationDate`, new Date());
        },

        targetLensData() {
            return get(this.calculatorResults, 'result.targetTable', []).map((result) =>
                Object.values(result).every(isEmpty) ? {} : result
            );
        },

        getListLensData() {
            return [...this.targetLensData, ...[{}, {}, {}, {}, {}]];
        },

        allLensInfo() {
            return get(this.calculatorResults, `result.allLensInfo`, []);
        },

        lensInformation() {
            return get(this.calculatorResults, 'result.lensInformation', {});
        },

        apiInformation() {
            return get(this.calculatorResults, 'api', {});
        },

        selectedRowIndex() {
            return this.targetLensData.findIndex((row) => isEqual(row, this.selectedRow));
        },

        isOUSZone() {
            return this.currentZone === ZoneCodes.OUS;
        },

        lengthOptions() {
            // Sets lengthOptions from lensInformation as it can change depending on the calculation.
            const options = (cloneDeep(this.lensInformation.lengthOptions) || [])
                .sort()
                .map((value) => ({
                    value: parseFloat(value),
                    text:
                        this.suggestedLengthOption.value == value
                            ? this.suggestedLengthOption.text
                            : `${formatNumber(value, 1, this.decimalSeparator)} mm`,
                }));

            // In case not lengthOptions were returned pushing default one
            if (!options.length) {
                const option = this.suggestedLengthOption.value
                    ? this.suggestedLengthOption
                    : {value: 0, text: this.t('notAvailable')};
                options.push(option);
            }
            return options;
        },

        powerOptions() {
            return this.lensInformation.powerOptions.map((value) => ({
                value,
                text: `+${formatNumber(value, this.cylinderDecimals, this.decimalSeparator)}`,
            }));
        },

        suggestedLengthOption() {
            const LABELS = {
                [ZoneCodes.OUS]: '',
                [ZoneCodes.US]: this.t('preop_DFURecommended'),
            };
            // Dividing by 10 because the calculator returns the ideal length as a non-decimal int that is the length multiplied by 10
            const value = this.lensInformation?.length / 10;
            return {
                value,
                text: `${formatNumber(value, 1, this.decimalSeparator)} mm ${
                    LABELS[this.currentZone]
                }`,
            };
        },

        suggestedLengthOptionText() {
            const {value, text} = this.suggestedLengthOption;
            return value ? text : this.t('preop_Unavailable');
        },

        icon() {
            return this[`${this.side}Icon`];
        },

        tableId() {
            return `${this.side}-lens-table`;
        },

        isPowerChanged() {
            return (
                Number(this.calculatorResults.cylinder) !==
                Number(this.calculatorResults.suggestedCylinder)
            );
        },

        saveButtonDisabled() {
            return (
                this.isNotAvailable(this.selectedRow) ||
                !this.selectedLengthOption ||
                this.showInventoryModal
            );
        },

        targetLensDescription() {
            /** If we do not have suggestedLength means calculator api didn't return it and we dont want to proceed to inventoty lookup */
            if (!isEmpty(this.selectedRow) && !this.selectedLengthOption) {
                return this.t('preop_Unavailable');
            }

            if (this.isNotAvailable(this.selectedRow)) {
                return isEmpty(this.selectedRow) ? '' : this.t('preop_NotAvailable');
            }

            //Build the description string to display
            let displayString = `${this.lensInformation.description} ${formatNumber(
                this.selectedLengthOption,
                1,
                this.decimalSeparator
            )} mm `;

            let measurementObj = {
                sphere: this.selectedRow.sphere,
            };

            if (this.lensInformation.calculationType.includes(LensModelCodes.TORIC)) {
                (measurementObj.cylinder = this.selectedRow.cylinder),
                    (measurementObj.axis = this.lensInformation.targetAxis);
            }

            displayString += this.lensDescriptionObjToString(measurementObj, this.decimalSeparator);
            return displayString;
        },

        isLocked() {
            return this.dataSet.status === Status.LOCKED;
        },

        isTargetLensPicked() {
            return this.dataSet.status === Status.TARGET_LENS_PICKED;
        },

        canLookupInventory() {
            return (
                this.isTargetLensPicked &&
                get(this.selectedRow, 'selectedLength') == this.selectedLengthOption &&
                this.calculatorResults.selectedLensIndex === this.selectedRowIndex
            );
        },
    },
    watch: {
        lengthSelectionEnabled(newValue) {
            //need to default back to rec length
            !newValue && this.selectRecLength(this.suggestedLengthOption.value);
        },
    },
    async mounted() {
        await this.calculatorResultsChange();
    },
};
</script>
<style lang="scss">
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';

.target-lens-table-container {
    cursor: default;
}

.target-lens-selection-table {
    &.locked {
        tr.b-table-row-selected {
            border: 4px solid black;
        }
    }

    tr {
        height: 50.5px;
    }

    td {
        font-size: 1.1rem;
    }
}

.side-text {
    font-size: 4.267rem;
    top: 100px;
}

.target-lens-header {
    background: #409026;
    padding-left: 10px;
    padding-right: 0px;
    padding-top: 10px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    &.locked {
        background: black;
    }
}

.target-lens-selection-field {
    &.form-control-plaintext {
        border: 1px solid;
        border-radius: 5px;
        color: black;
    }
    &::placeholder {
        color: black;
    }
    & .custom-modal {
        max-width: 200px;
        left: 10px;
    }
}

.body-text {
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
}

.container-card {
    position: relative;
    min-height: 400px;
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 8px;

    z-index: 3;
}

.msg-card {
    max-width: 60%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

tr.b-table-row-selected {
    outline: none;
}

.different-length-checkbox .custom-control-label {
    padding-top: 2px;
    font-weight: 400;
    color: #6c757d;
    font-size: 1rem !important; /* bootstrap override without it */
    line-height: 1.8rem !important; /* bootstrap override without it */
}

.cylinder-power-select {
    padding: 0px 63px 0 15px;
}

.fz-1rem {
    font-size: 1rem;
}

@include media-breakpoint-up(sm) {
    .target-lens-selection-table {
        overflow-y: hidden;
        tbody {
            display: block;
            max-height: 249.5px;
            overflow-y: scroll;
            border-bottom: 1px solid #e0e4e8;
        }
        tr {
            height: 50.5px;
            display: table;
            width: 100%;
            table-layout: fixed;
        }
        .b-table-row-selected {
            background: rgb(230, 255, 255) !important; /* bootstrap override without it */
            background-blend-mode: lighten;
        }
    }
}
@include media-breakpoint-up(xl) {
    .target-lens-selection-table tr th:nth-child(n + 4) {
        padding-left: 0 !important; /* bootstrap override without it */
    }
}
</style>
